<template>
  <main>
    <sqr-hero short="permissions" color="primary" is-bold/>
    <section class="section">
      <div class="container">
        <h3 class="title">{{$t('permissions_profile')}}</h3>
        <sqr-progress v-show="loading"/>
        <pre>{{record}}</pre>
        <sqr-error :error="loadError"/>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h3 class="title">{{$t('permissions_claims')}}</h3>
        <pre>{{claims}}</pre>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h3 class="title">{{$t('permissions_auth')}}</h3>
        <pre>{{user}}</pre>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Permissions',
  computed: {
    ...mapGetters('profile', ['record', 'loading', 'loadError']),
    ...mapGetters('auth', ['user', 'claims'])
  }
}
</script>